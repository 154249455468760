import router from "@/router";
import store from "@/store";
import axios from "axios";
import Dexie from 'dexie';

const entorno={
    getVersion(){
      return 'v. 1.0.0';
    },
    getEntorno(){
        let url=window.location.href;
        if(url.startsWith('https://pwc.segurosatocha.com'))
            return 'pro';
        else if(url.startsWith('https://pwc-pru.segurosatocha.com'))
            return 'pru';
        else
            return 'local';
    },

    getPublicUrl(){
        let entorno=this.getEntorno();
        switch (entorno){
            case 'pro':
                return '';
            case 'pru':
                return '';
            case 'local':
                return '';
        }
    },

    getUrl(){
        let entorno=this.getEntorno();
        switch (entorno){
            case 'pro':
                return 'https://pwc.segurosatocha.com/api';
            case 'pru':
                return 'https://pwc-pru.segurosatocha.com/api';
            case 'local':
                return 'http://segurosatocha.local/pwc/api';
        }
    },

    getApiUrl(){
        return this.getUrl()+'/api'
    },

    getInsidenetUrl(){
        let entorno=this.getEntorno();
        switch (entorno){
            case 'pro':
                return 'https://insidenet.segurosatocha.com';
            case 'pru':
                return 'https://insidenet-pru.segurosatocha.com';
            case 'local':
                return 'https://insidenet.segurosatocha.com';
        }
    },

    getDecesosUrl(){
        let entorno=this.getEntorno();
        switch (entorno){
            case 'pro':
                return 'https://segurosatocha.com/proyecto-decesos';
            case 'pru':
                return 'https://segurosatocha.com/proyecto-decesos';
            case 'local':
                return '';
        }
    },

    /**
     *
     * @param token
     * @param tipo 1=application/json, 2=multipart/form-data
     * @returns {{}}
     */
    getHeaders(token, tipo=1){
        let header={};
        switch (tipo){
            case 1:
                header['Content-Type']='application/json';
                break;
            case 2:
                header['Content-Type']='multipart/form-data';
                break;
        }
        if(token!=null)
            header['Authorization']='Bearer '+token;
        return header;
    },

    /**
     * @returns {AxiosInstance}
     */
    getAxios(){
        const miAxios=axios.create();
        miAxios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if(error.response.status===401){
                store.commit('set', ['snackbar', {show: true, text: 'Su sesión ha caducado', color: 'error', timeout: -1}]);
                router.push('/login');
            }
            else
                return Promise.reject(error);
        });
        return miAxios;
    },

    isObject(obj) {
        let type = typeof obj;
        return type === 'function' || type === 'object' && !!obj;
    },

    usuarioConectadoTienePermiso(usuario, permiso){
        return usuario.permisos.some(item => item === permiso);
    },


    // parametros={
    //     "include": "r1,r2",
    //     "fields": {
    //         "f1": "c11,c12",
    //         "f2": "c21,c22"
    //     }
    // };
    getUrlParametros(url, parametros){
        let inc=parametros.include;
        let par=parametros.fields;
        let salida=url;
        let nParametros=0;

        if(inc && inc.length>0){
            salida+='?include='+inc;
            nParametros++;
        }

        if(par)
            Object.keys(par).forEach(key => {
                if(nParametros===0)
                    salida+='?';
                else
                    salida+='&';
                salida+='fields['+key+']='+par[key];
                nParametros++;
            });
        return salida;
    },

    /**
     * Busca en @array el elemento que tiene [@campo1][@campo2]=@valor
     * @param array
     * @param campo1
     * @param campo2
     * @param valor
     * @returns {*}
     */
    findInArrayObject(array, campo1, campo2, valor){
        if(campo2==null)
            return array.find((x) => x[campo1] === valor);
        else
            return array.find((x) => x[campo1][campo2] === valor);
    },

    /**
     * Usa window.innerWidth para coger el ancho de la pantalla y dependiendo de ese ancho
     * se le asigna un nombre: xs, sm, md, lg, xl
     */
    getDisplayName(){
        const width=window.innerWidth;
        if(width<600)
            return 'xs';
        else if(width>=600 && width<960)
            return 'sm';
        else if(width>=960 && width<1264)
            return 'md';
        else if(width>=1264 && width<1904)
            return 'lg';
        else
            return 'xl';
    },

    /**
     *
     * @returns {Dexie}
     */
    initDexie(){
        const db = new Dexie('partesDB');
        db.version(1).stores({
            partes: '++id, parte.id, parte.dpo_numero, nuevo, estado.id, fechaSincronizacion',
            lov: 'id',
            versiones: 'id, codigo'
        });
        return db;
    },

    /**
     *
     * @param db Dexie
     * @param update boolean
     * @returns {Promise<null>}
     */
    async initDexieLov(db, update=false){
        let actualizar=update;
        if(await db.lov.count()===0)
            actualizar=true;

        if(actualizar){
            let lovMap=new Map();
            let lovArray=null;
            let lovVersion=null;

            await this.getAxios()
                .get(entorno.getApiUrl()+'/lov?fields[lovs]=id,tipo,descripcion', {"headers": this.getHeaders(store.getters.get('usuario').token)})
                .then(response => {
                    //Creamos un map con key=tipo
                    lovVersion=response.data.version;
                    response.data.lov.forEach(l => {
                        if (!lovMap.has(l.tipo)) {
                            lovArray=[];
                            lovArray.push({
                                id: l.id,
                                descripcion: l.descripcion
                            });
                            lovMap.set(l.tipo, lovArray);
                        }
                        else{
                            lovArray=lovMap.get(l.tipo);
                            lovArray.push({
                                id: l.id,
                                descripcion: l.descripcion
                            });
                        }
                    })
                });

            //Añadimos el map en la tabla de dexie lov
            lovMap.forEach(async (v, k, m) => {
                await db.lov.put({
                    id: k,
                    lov: v,
                });
            });

            //Guardamos la version de LOV
            await db.versiones.put({
                id: 1,
                codigo: 'lov',
                valor: lovVersion
            });
        }
        return null;
    },

    /**
     *
     * @param db Dexie
     * @param tipo
     * @returns {null}
     */
    async getDexieLov(db, tipo){
        let lov=await db.lov.get(tipo);
        return lov.lov;
    },

    /**
     * Devuelve true si hay conexión a internet
     * Funciona bien en dispositivos móviles. Pero no va bien en PC, si fuera necesario en PC revisar:
     * https://stackoverflow.com/questions/189430/detect-if-the-internet-connection-is-offline
     * https://github.com/HubSpot/offline
     * @returns {boolean}
     */
    isInternetOnline(){
        return navigator?.onLine;
    },
}

export default entorno
