import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({ usuario: state.usuario }),
})

export default new Vuex.Store({
    state: {
        //usuario: {},
        overlay: false,
        snackbar: {
            show: false,
            text: '',
            color: 'error',
            timeout: -1,
            link: null,
            linkText: null,
        },
        search: null,
        filter: {},
        online: navigator.onLine,
    },
    getters: {
        get: (state) => (name) => {
            return state[name];
        }
    },
    mutations: {
        set(state, [name, value]) {
            state[name] = value
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [vuexLocal.plugin]
})