import Vue from 'vue';
import VueRouter from 'vue-router';
import LayOut from "@/views/LayOut.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: LayOut,
        children: [
            {
                path: '/partes',
                component: () => import('@/views/DasPartes.vue')
            },
            {
                path: '/partes/nuevo',
                component: () => import('@/views/DasParte.vue')
            },
            {
                path: '/partes/editar/:id',
                component: () => import('@/views/DasParte.vue')
            },
            {
                path: '/dpartes',
                component: () => import('@/views/DasPartesD.vue')
            },
            {
                path: '/dpartes/nuevo',
                component: () => import('@/views/DasParteD.vue')
            },
            {
                path: '/dpartes/editar/:id',
                component: () => import('@/views/DasParteD.vue')
            },
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/LogIn.vue')
    },
    {
        path: '/logout',
        component: () => import('@/views/LogIn.vue')
    },
];

const router = new VueRouter({
    base: '/',
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/logout'];
    const authRequired = !publicPages.some(path => to.path.startsWith(path));

    if (authRequired && !loggedIn()) {
        return next('/login')
    }

    next();
    //setTimeout(() => next(), 10)
})

function loggedIn(){
    // Comprobamos si se ha creado el estado del usuario
    if (!store.state.usuario) return false;
    if (Object.keys(store.state.usuario).length === 0) return false;
    return true;
}

export default router;